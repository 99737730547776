<template>
  <div>
    <button
      class="btn btn-outline-blue mb-3"
      @click="addNewClass"
      v-if="$can({ key: 'clients', expectedPermission: 'edit' })"
    >
      <icon type="plus" /> Add Class
    </button>

    <alert v-if="isLoading" />
    <alert
      :hideLoader="true"
      class="my-4"
      v-if="!isLoading && patient != null && classifications.length == 0"
    >
      No Classes Attached</alert
    >
    <div
      class="card table-container"
      v-if="
        patient != null && patient.user != null && classifications.length > 0
      "
    >
      <table class="table table-striped mb-0">
        <thead>
          <tr>
            <th>Subject</th>
            <th
              v-if="
                user &&
                (user.isAdmin || user.isManagingAdmin || user.isProvider) &&
                $can({ key: 'clients', expectedPermission: 'edit' })
              "
            >
              detach
            </th>
            <th class="desktop-only"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="classification in classifications"
            :key="classification.id"
          >
            <td>{{ classification.subject }}</td>

            <td v-if="$can({ key: 'clients', expectedPermission: 'edit' })">
              <span class="ml-2 text-danger pointer-cursor"
                ><i
                  v-if="!isSaving"
                  class="fas fa-unlink"
                  @click="detachSelectedClass($event, classification.id)"
                ></i>
                <i class="fa fa-spin fa-circle-notch" v-if="isSaving"></i>
              </span>
            </td>
            <td class="desktop-only"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "patient-classifications",
  data() {
    return {
      patientId: this.$route.params.id,
      isLoading: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      patient: (state) => state.patients.patient,
      classifications: (state) => state.patients.patient.user.classifications,
    }),
  },

  methods: {
    ...mapActions({
      detach: "classifications/detachClassFromUser",
      getPatientData: "patients/get",
    }),
    addNewClass: function () {
      this.$router.push({
        name: "patients.classes.create",
        params: { id: this.patientId },
        query: { src: "classes" },
      });
    },

    detachSelectedClass: function ($event, id) {
      $event.preventDefault();
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, detach it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            vm.isSaving = true;
            vm.detach({ id: id, user: vm.patient.user_id }).then((detach) => {
              vm.isSaving = false;
              if (detach) {
                this.getPatientData({ id: vm.patientId });
              } else {
                swalWithBootstrapButtons.fire(
                  "Error!",
                  "Something went wrong...",
                  "error"
                );
              }
            });
          }
        });
    },
  },
};
</script>
